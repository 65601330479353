import { StepSyncProgress, IStepSyncProgressOptions, IStepSyncProgressJSON } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import type { IOfflineSyncProgress, IOnlineSyncProgress, ITruncateProgress } from 'o365.pwa.declaration.sw.strategies.api.pwa.strategy.d.ts';

export interface IDataObjectProgressJSON extends IStepSyncProgressJSON {
    offlineSyncInProgress?: IOfflineSyncProgress;
    offlineSyncCompletedProgress?: IOfflineSyncProgress;
    onlineSyncInProgress?: IOnlineSyncProgress;
    onlineSyncCompletedProgress?: IOnlineSyncProgress;
    truncateInProgress?: ITruncateProgress;
    truncateCompletedProgress?: ITruncateProgress;
}

export interface IDataObjectProgressOptions extends IStepSyncProgressOptions {
    offlineSyncInProgress?: IOfflineSyncProgress;
    offlineSyncCompletedProgress?: IOfflineSyncProgress;
    onlineSyncInProgress?: IOnlineSyncProgress;
    onlineSyncCompletedProgress?: IOnlineSyncProgress;
    truncateInProgress?: ITruncateProgress;
    truncateCompletedProgress?: ITruncateProgress;
}

export class DataObjectProgress extends StepSyncProgress {
    public offlineSyncInProgress?: IOfflineSyncProgress;
    public offlineSyncCompletedProgress?: IOfflineSyncProgress;
    public onlineSyncInProgress?: IOnlineSyncProgress;
    public onlineSyncCompletedProgress?: IOnlineSyncProgress;
    public truncateInProgress?: ITruncateProgress;
    public truncateCompletedProgress?: ITruncateProgress;

    public get offlineSyncProgress(): IOfflineSyncProgress | undefined {
        return this.offlineSyncCompletedProgress ?? this.offlineSyncInProgress;
    }

    public get onlineSyncProgress(): IOnlineSyncProgress | undefined {
        return this.onlineSyncCompletedProgress ?? this.onlineSyncInProgress;
    }

    public get truncateProgress(): ITruncateProgress | undefined {
        return this.truncateCompletedProgress ?? this.truncateInProgress;
    }

    private get progressStatusTotal(): number {
        switch (this.syncType) {
            case 'OFFLINE-SYNC':
                if (!this.offlineSyncProgress) {
                    return 0;
                }

                return (this.offlineSyncProgress.recordsToRetrieve + this.offlineSyncProgress.filesToRetrieve) * 2;
            case 'ONLINE-SYNC':
                if (!this.onlineSyncProgress) {
                    return 0;
                }

                return this.onlineSyncProgress.recordsToUpload + this.onlineSyncProgress.filesToUpload;
        }

        return 0;
    }

    private get progressStatusSuccessTotal(): number {
        switch (this.syncType) {
            case 'OFFLINE-SYNC':
                if (!this.offlineSyncProgress) {
                    return 0;
                }

                return this.offlineSyncProgress.recordsRetrieved + this.offlineSyncProgress.recordsStored + this.offlineSyncProgress.filesRetrieved + this.offlineSyncProgress.filesStored;
            case 'ONLINE-SYNC':
                if (!this.onlineSyncProgress) {
                    return 0;
                }

                return this.onlineSyncProgress.recordsUploaded + this.onlineSyncProgress.filesUploaded;
        }

        return 0;
    }

    private get progressStatusErrorTotal(): number {
        switch (this.syncType) {
            case 'OFFLINE-SYNC':
                if (!this.offlineSyncProgress) {
                    return 0;
                }

                return this.offlineSyncProgress.recordsRetrievedWithError + this.offlineSyncProgress.recordsStoredWithError + this.offlineSyncProgress.filesRetrievedWithError + this.offlineSyncProgress.filesStoredWithError;
            case 'ONLINE-SYNC':
                if (!this.onlineSyncProgress) {
                    return 0;
                }

                return this.onlineSyncProgress.recordsUploadedWithError + this.onlineSyncProgress.filesUploadedWithError;
        }

        return 0;
    }

    public get progressStatusPending(): number {
        switch (this.syncType) {
            case 'OFFLINE-SYNC':
                if (!this.offlineSyncProgress) {
                    return 0;
                }

                if (
                    this.offlineSyncProgress.generateOfflineDataCompletedWithError ||
                    this.offlineSyncProgress.retrieveRowCountCompletedWithError ||
                    this.offlineSyncProgress.retrieveRecordsCompletedWithError ||
                    this.offlineSyncProgress.retrieveFilesCompletedWithError
                ) {
                    return 0;
                }

                if (
                    (this.offlineSyncProgress.generateOfflineDataStarted && !this.offlineSyncProgress.generateOfflineDataCompleted && !this.offlineSyncProgress.generateOfflineDataCompletedWithError) ||
                    (this.offlineSyncProgress.retrieveRowCountStarted && !this.offlineSyncProgress.retrieveRowCountCompleted && !this.offlineSyncProgress.retrieveRowCountCompletedWithError)
                ) {
                    return 100;
                }

                break;
            case 'ONLINE-SYNC':
                if (!this.onlineSyncProgress) {
                    return 0;
                }

                if (
                    this.onlineSyncProgress.retrieveRowCountCompletedWithError ||
                    this.onlineSyncProgress.uploadRecordsCompletedWithError ||
                    this.onlineSyncProgress.uploadFilesCompletedWithError
                ) {
                    return 0;
                }

                if (this.onlineSyncProgress.retrieveRowCountStarted && !this.onlineSyncProgress.retrieveRowCountCompleted && !this.onlineSyncProgress.retrieveRowCountCompletedError) {
                    return 100;
                }
                break;
            default:
                return 0;
        }

        const total = this.progressStatusTotal;

        if (total === 0) {
            return 0;
        }

        const success = this.progressStatusSuccessTotal;
        const error = this.progressStatusErrorTotal;

        let result = (total - success - error) / total * 100;
        
        return result;
    }

    public get progressStatusSuccess(): number {
        switch (this.syncType) {
            case 'OFFLINE-SYNC':
                if (!this.offlineSyncProgress) {
                    return 0;
                }

                if (
                    this.offlineSyncProgress.generateOfflineDataCompletedWithError ||
                    this.offlineSyncProgress.retrieveRowCountCompletedWithError ||
                    this.offlineSyncProgress.retrieveRecordsCompletedWithError ||
                    this.offlineSyncProgress.retrieveFilesCompletedWithError
                ) {
                    return 0;
                }

                if (
                    (this.offlineSyncProgress.generateOfflineDataStarted && !this.offlineSyncProgress.generateOfflineDataCompleted && !this.offlineSyncProgress.generateOfflineDataCompletedWithError) ||
                    (this.offlineSyncProgress.retrieveRowCountStarted && !this.offlineSyncProgress.retrieveRowCountCompleted && !this.offlineSyncProgress.retrieveRowCountCompletedWithError)
                ) {
                    return 0;
                }

                if (this.progressStatusTotal === 0) {
                    if (this.offlineSyncProgress.retrieveRowCountStarted && this.offlineSyncProgress.retrieveRowCountCompleted && !this.offlineSyncProgress.retrieveRowCountCompletedWithError) {
                        return 100;
                    } else {
                        return 0;
                    }
                }
                break;
            case 'ONLINE-SYNC':
                if (!this.onlineSyncProgress) {
                    return 0;
                }

                if (
                    this.onlineSyncProgress.retrieveRowCountCompletedWithError ||
                    this.onlineSyncProgress.uploadRecordsCompletedWithError ||
                    this.onlineSyncProgress.uploadFilesCompletedWithError
                ) {
                    return 0;
                }

                if (this.onlineSyncProgress.retrieveRowCountStarted && !this.onlineSyncProgress.retrieveRowCountCompleted && !this.onlineSyncProgress.retrieveRowCountCompletedWithError) {
                    return 0;
                }

                if (this.progressStatusTotal === 0) {
                    if (this.onlineSyncProgress.retrieveRowCountStarted && this.onlineSyncProgress.retrieveRowCountCompleted && !this.onlineSyncProgress.retrieveRowCountCompletedWithError) {
                        return 100;
                    } else {
                        return 0;
                    }
                }
                break;
            default:
                return 0;
        }

        let result = this.progressStatusSuccessTotal / this.progressStatusTotal * 100;

        return result;
    }

    public get progressStatusError(): number {
        switch (this.syncType) {
            case 'OFFLINE-SYNC':
                if (!this.offlineSyncProgress) {
                    return 0;
                }

                if (
                    this.offlineSyncProgress.generateOfflineDataCompletedWithError ||
                    this.offlineSyncProgress.retrieveRowCountCompletedWithError ||
                    this.offlineSyncProgress.retrieveRecordsCompletedWithError ||
                    this.offlineSyncProgress.retrieveFilesCompletedWithError
                ) {
                    return 100;
                }
                break;
            case 'ONLINE-SYNC':
                if (!this.onlineSyncProgress) {
                    return 0;
                }

                if (
                    this.onlineSyncProgress.retrieveRowCountCompletedWithError ||
                    this.onlineSyncProgress.uploadRecordsCompletedWithError ||
                    this.onlineSyncProgress.uploadFilesCompletedWithError
                ) {
                    return 100;
                }
                break;
            default:
                return 0;
        }

        const total = this.progressStatusTotal;

        if (total === 0) {
            return 0;
        }

        const error = this.progressStatusErrorTotal;

        const result = error / total * 100;
        
        return result;
    }

    get progressStatusRecordsCompleted(): number {
        switch (this.syncType) {
            case 'OFFLINE-SYNC':
                if (!this.offlineSyncProgress) {
                    return 0;
                }

                if (!this.offlineSyncProgress.retrieveRowCountCompleted) {
                    return 0;
                }

                return this.offlineSyncProgress.recordsStored;
            case 'ONLINE-SYNC':
                if (!this.onlineSyncProgress) {
                    return 0;
                }

                if (!this.onlineSyncProgress.retrieveRowCountCompleted) {
                    return 0;
                }

                return this.onlineSyncProgress.recordsUploaded;
        }

        return 0;
    }

    get progressStatusRecordsToDownload(): number {
        switch (this.syncType) {
            case 'OFFLINE-SYNC':
                if (!this.offlineSyncProgress) {
                    return 0;
                }

                if (!this.offlineSyncProgress.retrieveRowCountCompleted) {
                    return 0;
                }

                return this.offlineSyncProgress.recordsToRetrieve;
            case 'ONLINE-SYNC':
                if (!this.onlineSyncProgress) {
                    return 0;
                }

                if (!this.onlineSyncProgress.retrieveRowCountCompleted) {
                    return 0;
                }

                return this.onlineSyncProgress.recordsToUpload;
        }

        return 0;
    }

    get progressStatusFilesCompleted(): number {
        switch (this.syncType) {
            case 'OFFLINE-SYNC':
                if (!this.offlineSyncProgress) {
                    return 0;
                }

                if (!this.offlineSyncProgress.retrieveRowCountCompleted) {
                    return 0;
                }

                return this.offlineSyncProgress.filesStored;
            case 'ONLINE-SYNC':
                if (!this.onlineSyncProgress) {
                    return 0;
                }

                if (!this.onlineSyncProgress.retrieveRowCountCompleted) {
                    return 0;
                }

                return this.onlineSyncProgress.filesUploaded;
        }

        return 0;
    }

    get progressStatusFilesToDownload(): number {
        switch (this.syncType) {
            case 'OFFLINE-SYNC':
                if (!this.offlineSyncProgress) {
                    return 0;
                }

                if (!this.offlineSyncProgress.retrieveRowCountCompleted) {
                    return 0;
                }

                return this.offlineSyncProgress.filesToRetrieve;
            case 'ONLINE-SYNC':
                if (!this.onlineSyncProgress) {
                    return 0;
                }

                if (!this.onlineSyncProgress.retrieveRowCountCompleted) {
                    return 0;
                }

                return this.onlineSyncProgress.filesToUpload;
        }

        return 0;
    }

    constructor(options: IDataObjectProgressOptions) {
        super(options);

        this.offlineSyncInProgress = options.offlineSyncInProgress;
        this.offlineSyncCompletedProgress = options.offlineSyncCompletedProgress;
        this.onlineSyncInProgress = options.onlineSyncInProgress;
        this.onlineSyncCompletedProgress = options.onlineSyncCompletedProgress;
        this.truncateInProgress = options.truncateInProgress;
        this.truncateCompletedProgress = options.truncateCompletedProgress;
    }

    public toJSON(): IDataObjectProgressJSON {
        return Object.assign(super.toJSON(), this);
    }
}
